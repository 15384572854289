import React, { useState } from "react"
import Turnstile from "react-turnstile"
import { GATSBY_CLOUDFLARE_TURNSTILE_CAPTCHA_SITE_KEY } from "gatsby-env-variables"

const TurnstileCaptcha = ({ setCheckCaptcha, onSuccess }) => {
    const siteKey = GATSBY_CLOUDFLARE_TURNSTILE_CAPTCHA_SITE_KEY
    const handleSuccess = () => {
      setCheckCaptcha(true)
       onSuccess()
    }

    return (
      <div>
        <div className="is-centered">
          <Turnstile 
            sitekey={siteKey}
            onVerify={handleSuccess}
          />
        </div>
      </div>
    )
}

export default TurnstileCaptcha