import React, { useState, useEffect, useContext } from "react"
import moment from "moment"
import classNames from "classnames"

import SignInNoticeModal from "./SignInNoticeModal"

import styles from "./utils/layout.module.scss"
import {
  hasSeenNotification,
  setNotificationAsSeen,
} from "./services/notification"
import {
  GATSBY_ORDER_AS_GUEST,
  GATSBY_ORDER_AS_GUEST_KEY,
  GATSBY_ORDER_AS_GUEST_VALUE,
} from "gatsby-env-variables"
import { isBrowser } from "../../services/general"
import { AppContext } from "../../context/AppContext"
import { getBannerMessage } from "./services/getBannerMessage"

const Notification = ({ isOTPpage }) => {
  const { dispatch } = useContext(AppContext)
  let dateToday = moment().format("YYYY-MM-DD hh:mm:ss")
  const [notificationHidden, setNotificationHidden] = useState(false)
  const hasSeenNotificationMessage = hasSeenNotification()
  const [notification, setNotification] = useState(null)

  //const [verifyOTP, setVerifyOTP] = useState(null)

  useEffect(() => {
    fetchBanner()
  }, [])

  const fetchBanner = async () => {
    const banner = await getBannerMessage(dateToday)
    const otpMessage = "If you have not received an OTP within 5 minutes, please reach out to us at pulsecare@medgrocer.com. Thank you."
    let updatedBanner = isOTPpage ? `${banner}\n ${otpMessage}` : banner
    setNotification(updatedBanner)
  }


  // let isOrderingAsGuest = false
  // if (GATSBY_ORDER_AS_GUEST) {
  //   if (isBrowser()) {
  //     isOrderingAsGuest =
  //       window.sessionStorage.getItem(GATSBY_ORDER_AS_GUEST_KEY) ===
  //       GATSBY_ORDER_AS_GUEST_VALUE
  //   }
  // }


  if (notification && !hasSeenNotificationMessage)
    return (
      <div
        className={classNames(
          "notification is-warning",
          {
            "is-hidden": notificationHidden,
          },
          styles["notification"]
        )}
      >
        <button
          aria-label="Delete Button"
          className="delete"
          onClick={() => {
            setNotificationHidden(true)
            setNotificationAsSeen()
          }}
        ></button>
        <div className="has-text-centered is-size-6">
          <p
            dangerouslySetInnerHTML={
              notification ? { __html: notification } : null
            }
          />
        </div>
        <div>
        </div>
      </div>
    )

  return null
}

export default Notification
